.application {
  position: relative;
  display: flex;
  transition: all ease-out 300ms;
  background-color: var(--color-pad);
  border-radius: 1vmax;
  padding: 3vmax 3vmax 4vmax 3vmax;
  transition: all 150ms ease-out;
}

.application__cover {
  display: flex;
  height: max(26px, 8vmax);
  width: max(26px, 8vmax);
  border-radius: 2vmax;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0 2vmax 0 0;
}

.application__info {
  display: flex;
  flex-direction: column;
  padding-top: 1vmax;
  width: 100%;
}

.application__title {
  margin-bottom: max(8px, 0.5vmax);
}

.application__description {
  margin-bottom: max(16px, 1vmax);
}

.application__links {
  display: flex;
}

.application__link {
  margin: 0 1.5vmax 0 0;
}

.application__link:last-child {
  margin: 0;
}

.application__commentary {
  position: absolute;
  opacity: 0;
  right: 6vmax;
  bottom: 60%;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(24px);
  padding: 2vmax;
  max-width: 20vmax;
  border-radius: 1vmax;
  transform: translate(1vmax, 1vmax) rotate(0);
  transition: all 100ms ease-in;
}

.application__commentary-title {
  margin: 0 0 1vmax 0;
}

.application__commentary-author {
  text-align: end;
}

.application__commentary-sym-1,
.application__commentary-sym-2 {
  position: absolute;
}

.application__commentary-sym-1 {
  opacity: 0;
  top: -0.5vmax;
  left: -0.5vmax;
  transform: rotate(-16deg);
}

.application__commentary-sym-2 {
  opacity: 0;
  right: -4vmax;
  bottom: 2vmax;
  transition: all 300ms ease-in-out;
}

.application:hover {
  z-index: 1;
  background-color: var(--color-secondary);
  transform: scale(1.05);
  transition: all 300ms ease-in-out;
}

.application:hover .application__commentary-sym-1 {
  opacity: 1;
  top: -1.5vmax;
  left: -1.5vmax;
  transition: all 600ms ease-out;
}

.application:hover .application__commentary-sym-2 {
  opacity: 1;
  right: -5vmax;
  bottom: 3vmax;
  transition: all 600ms ease-out;
}

.application__isNewLable {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 1vmax 0 1vmax;  
  overflow: hidden;
  background: var(--color-secondary);
  padding: 0.5vmax 1.2vmax 0.6vmax 1.2vmax;
  transition: all 300ms ease-in-out;
}

/* application card for mobile */
@media screen and (max-width: 480px) {
  .application {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5vmax;
  }

  .application__cover {
    margin: 0;
  }

  .application__title,
  .application__info {
    text-align: center;
  }

  .application__commentary {
    display: none;
  }

  .application__links {
    display: flex;
    justify-content: center;
  }
}

/* disable commentary on touchscreen devices */
@media (hover: hover) and (pointer: fine) {
  .application:hover .application__commentary {
    opacity: 1;
    transform: translate(0, 0) rotate(6deg);
    transition: all 300ms ease-in-out 150ms;
  }
}
